<template>
  <div class="ready">
    <h1 class="ready__title intuit-title">LUMIDROID</h1>
    <h2 class="ready__tagline intuit-subtitle">GRAB SOME INSPIRATION, GRAB THE PENCIL, START DRAWING.</h2>
    <p class="ready__copy intuit-copy">Don’t be afraid to get creative. Pro tip: don’t lift the pencil off the screen
      when drawing, it scares the robot!</p>
    <button class="ready__button intuit-button intuit-button--primary" @click.prevent="emit('start')">Start</button>
  </div>
</template>

<script setup>
const emit = defineEmits(['start']);
</script>

<style lang="scss">
.ready {
  text-align: center;

  &__copy {
    margin: (150px * $scale) 0;
  }

}
</style>
