<template>

  <div class="qrCode">
    <h1 class="qrCode__title intuit-title">SCAN THE QR CODE,<br/>SHARE YOUR MASTERPIECE</h1>

    <div class="qrCode__imageHolder">
      <img alt="QR Code" class="qrCode__image" :src="qrCodeUrl"/>
    </div>


    <p class="qrCode__copy intuit-copy">Look for the QR code on the screen to download and share your work with the
      world</p>

    <div class="qrCode__reset">
      <button type="button" class="intuit-button" @click="reset">Reset</button>
    </div>
  </div>


</template>

<script setup>

const props = defineProps({
  qrCodeUrl: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['reset']);

function reset() {
  emit('reset');
}
</script>

<style lang="scss">
.qrCode {
  text-align: center;

  &__imageHolder {
    margin-bottom: 70px * $scale;
    display: inline-block;
  }

  &__image {
    display: block;
    border: 30px * $scale solid var(--black);
  }

  &__copy {
    margin-bottom: 70px * $scale;
  }
}
</style>
