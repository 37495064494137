<template>
    <div class="form-group" :class="field.classes">
        <label v-if="field.label">{{field.label}}</label>
        <multiselect :placeholder="field.placeholder" @input="handleInput" :required="!!field.required" v-model="field.value" :options="options" :custom-label="nameWithId"></multiselect>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        name: 'selectField',
        components: {
            Multiselect
        },
        props : [
            'props'
        ],
        data: function () {
            return {
                options: [],
                field : {
                    value: null,
                    options: [],
                    classes:'',
                    placeholder: 'Select option'
                },
            };
        },
        methods: {
            nameWithId ({ key, value }) {
                return `${value}`
            },
            handleInput (e) {
                this.$set(this.field, 'value', e);
            },
            //this is not ideal but for now as we can't easily pass an object as value, it will do.
            getObjectFromOptionsByKey(options, value){
                for(var option in options){
                    if(options.hasOwnProperty(option)){
                        if(options[option].key == value){
                            return options[option];
                        }
                    }

                }
            }
        },
        created() {
            //this.options = this.props.options;
            this.field = this.props.field;
            if(typeof this.field.placeholder == 'undefined'){
              this.field.placeholder = 'Select option';
            }
            this.options = this.field.options;
            this.$set(this.field,'value', this.getObjectFromOptionsByKey(this.field.options, this.field.value));
        }
    }
</script>