<template>
  <div :class="field.classes">
    <div :class="[field.label ? 'form-group' : 'hidden-field-wrapper', {'form-group--error': $v.field.value.$error}]">
      <label v-if="field.label" class="form__label" :for="field.name" :class="field.inputType !== 'date' ? 'bmd-label-floating' : ''">{{field.label}}</label>
      <input @input="$v.field.value.$touch()" :class="{'form-input--error': $v.field.value.$error}" v-on:change="watchFileInput" :disabled="!!field.disabled" class="form-control form__input" v-model="field.value" :type="field.inputType" :name="field.name" :placeholder="field.placeholder" :value="field.value" :id="field.id"/>
    </div>
  </div>
</template>

<script>
import {fieldFunctions} from "../../mixins/fieldFunctions";

export default {
  name: 'fileField',
  mixins: [fieldFunctions],
  data: function () {
    return {
      field : {
        id: '',
        name: '',
        label: '',
        inputType: 'text',
        placeholder: '',
        value: '',
        disabled: false,
        callback: '',
        validations: {},
        classes: '',
        file: ''
      },
      file: {},
      fieldValidations: null,
      enabledValidations: {}
    };
  },

  methods: {
    watchFileInput: function(event) {
      this.field.file = event.target.files[0];
    },

    //move somewhere useful?
    readFile: async (file) => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          // error occurred
          reader.abort();
          reject(new DOMException("Problem parsing input file."));
        };
        reader.onload = () => {
          // binary data
          resolve(reader.result);
        };
        reader.readAsText(file);
      });
    }
  }
}
</script>