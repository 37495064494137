<template>
  <div :class="classes">
    <div v-if="inputType === 'checkbox'" class="form-check">
      <input :id="id"
             :checked="value === true"
             :disabled="disabled"
             :name="name"
             :type="inputType"
             class="form-check-input"
             @input="$emit('update:model', $event)"
      />
      <label v-if="label" :for="id" class="form-check-label">{{ label }}</label>
    </div>

    <div v-else-if="inputType === 'toggleswitch'" class="form-check form-switch">
      <div v-if="label" class="form__label">{{ label }}</div>
      <label>
        <span v-if="!value">{{ switchOff }}</span>
        <input :id="id"
               :disabled="disabled"
               :name="name"
               class="form-check-input"
               type="checkbox"
        >
        <span v-if="value">{{ switchOn }}</span>
      </label>
    </div>

    <div v-else-if="inputType === 'holding'"/>

    <div v-else
         :class="[
               (inputType !== 'hidden' || label) ? 'form-group' : 'hidden-field-wrapper',
            ]">
      <label v-if="label"
             :class="inputType !== 'date' ? 'bmd-label-floating' : ''"
             :for="name"
             class="form__label">{{ label }}</label>
      <div v-if="icon" class="input-group-addon" v-html="icon"></div>
      <input :id="id"
             :disabled="disabled"
             :name="name"
             :placeholder="placeholder"
             :type="inputType"
             :value="value"
             class="form-control form__input"
             @input="$emit('update:model', $event)"
      />
    </div>
    <div v-if="help" class="form-text">{{ help }}</div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import classResolver from "@/_core/services/classResolver";
import classesProp from '@/_core/components/_properties/classes';

const props = defineProps({
  ...classesProp,
  id: {
    type: String,
    required: true
  },
  inputType: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'input'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  validations: {
    type: Array,
    default: () => []
  },
  value: {
    type: [String, Number, Boolean, null],
    default: null,
  },
  switchOff: {
    type: String,
    default: '',
  },
  switchOn: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  help: {
    type: String,
    default: '',
  },
  checked: {
    type: Boolean,
    default: false
  }
});

defineEmits(['update:model']);

const model = ref(props.model);

const classes = computed(() => classResolver({
  [props.classes]: true,
  'form-check-wrapper': props.inputType === 'checkbox'

}))
</script>

<!--<script>-->
<!--import {fieldFunctions} from "../../mixins/fieldFunctions";-->

<!--export default {-->
<!--  name: 'inputField',-->
<!--  mixins: [fieldFunctions],-->
<!--  data: function () {-->
<!--    return {-->
<!--      field: {-->
<!--        id: '',-->
<!--        name: '',-->
<!--        label: '',-->
<!--        inputType: 'text',-->
<!--        placeholder: '',-->
<!--        value: '',-->
<!--        disabled: false,-->
<!--        callback: '',-->
<!--        validations: {},-->
<!--        classes: '',-->
<!--        icon: '',-->
<!--        help: '',-->
<!--      },-->
<!--      fieldValidations: null,-->
<!--      enabledValidations: {}-->
<!--    };-->
<!--  },-->
<!--}-->
<!--</script>-->
<style lang="scss">
.hidden-field-wrapper {
  display: none;
}
</style>
