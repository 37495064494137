<template>
  <div class="waiting">

    <div class="waiting__spinner">
      <svg
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            id="circlePath"
            fill="transparent"
            d="
        M 10, 50
        a 40,40 0 1,1 80,0
        40,40 0 1,1 -80,0
      "
        />
        <text>
          <textPath href="#circlePath" fill="currentColor">
            MASTERPIECE LOADING * MASTERPIECE LOADING * MASTERPIECE LOADING *
          </textPath>
        </text>
      </svg>

    </div>

    <h2 class="waiting__title intuit-subtitle">WHILE YOU WERE DRAWING,<br/>WE WERE CAPTURING.</h2>
    <p class="waiting__copy intuit-copy">Say cheese! A long exposure camera is taking a photo of the process to capture
      the unique light trail created by the robotic arm.</p>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted} from "vue";
import useApi from "@/_core/services/api";
import useApiResponseProcessor from "@/_core/services/ApiResponseProcessor";

const {processResponse} = useApiResponseProcessor()

const props = defineProps({
  checkUrlPattern: {
    type: String,
    required: true
  },

  session: {
    type: String,
    required: true
  }
})

const api = useApi();

const checkUrl = computed(() => props.checkUrlPattern.replace("{session}", props.session));

const emit = defineEmits(['complete']);
let interval = null;

onMounted(() => {
  interval = setInterval(async () => {
    const {complete, qrCode} = await processResponse(await api.get(checkUrl.value));

    if (complete === true) {
      clearInterval(interval);

      emit('complete', qrCode);
    }
  }, 3000)
});

onUnmounted(() => {
  clearInterval(interval);
});

</script>

<style lang="scss">
.waiting {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__spinner {
    width: 550px * $scale;
    font-size: 4.5px;
    font-weight: bold;
    font-family: "Basically A Mono", monospace;
    color: var(--white);
    animation: spin 20s linear infinite;
    margin-bottom: 70px * $scale
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
