
<template>
    <div class="menu-group">
        <a v-if="group.links" v-bind:href="'#' + group.href.replace('/', '').replace(/\./g, '')" data-bs-toggle="collapse" role="button" aria-expanded="false" v-bind:aria-controls="group.href.replace('/', '').replace(/\./g, '')" class="dropdown-toggle"><i v-if="group.icon" v-bind:class="['fal', 'fa-fw', 'fa-'+group.icon]"></i> <span class="parentLink">{{group.title}}</span></a>
        <ul v-if="group.links" class="collapse list-unstyled" v-bind:id="group.href.replace('/', '').replace(/\./g, '')">
          <li class="dropdown-wrapper">
            <ul class="dropdown">
              <li v-for="link in group.links">
                  <a v-if="link.links" v-bind:href="'#' + link.href.replace('/', '').replace(/\./g, '')" v-bind:id="link.title" data-bs-toggle="collapse" role="button" aria-expanded="false" v-bind:aria-controls="link.href.replace('/', '').replace(/\./g, '')" class="dropdown-toggle"><i v-if="link.icon" v-bind:class="['fal', 'fa-fw', 'fa-'+link.icon]"></i> <span class="parentLink">{{link.title}}</span></a>
                    <ul v-if="link.links" v-bind:id="link.href.replace('/', '').replace(/\./g, '')">
                        <li v-for="subLink in link.links">
                            <router-link v-bind:to="subLink.href" :target="subLink.target ? subLink.target : '_self'"><i v-if="subLink.icon" v-bind:class="['fal', 'fa-fw', 'fa-'+subLink.icon]"></i> {{subLink.title}}</router-link>
                        </li>
                    </ul>
                    <router-link v-else v-bind:to="link.href" :target="link.target ? link.target : '_self'"><i v-if="link.icon" v-bind:class="['fal', 'fa-fw', 'fa-'+link.icon]"></i> {{link.title}}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <router-link v-else v-bind:to="group.href" :target="group.target ? group.target : '_self'"><i v-if="group.icon" v-bind:class="['fal', 'fa-fw', 'fa-'+group.icon]"></i> <span class="parentLink">{{group.title}}</span></router-link>
    </div>
</template>
<script>
    export default {
        name: 'NavList',
        props : ['group'],
        data: function () {
            return {}
        }
    }

    //$('body').on('show.bs.collapse','.collapse', function() {
    //    $('.show').collapse('hide');
    //});

</script>
