<template>
  <div :class="field.classes" class="form-group textarea bmd-form-group">
    <label v-if="field.label" :class="'bmd-label-floating'" :for="field.name">{{ field.label }}</label>
    <tip-tap-editor
        v-if="enable_editor"
        v-bind:props="field"
    ></tip-tap-editor>
    <textarea
        v-else
        :id="field.id"
        v-model="field.value"
        :cols="cols"
        :name="field.name"
        :placeholder="field.placeholder"
        :rows="rows"
        class="form-control"
    ></textarea>
    <div v-if="field.help" class="form-text">{{ field.help }}</div>
  </div>
</template>

<script>
import {commonProperties} from "../../mixins/commonProperties";
import TipTapEditor from "../tiptap/TipTapEditor.vue";

export default {
  name: 'textareaField',
  components: {TipTapEditor},
  props: [
    'props'
  ],
  mixins: [commonProperties],
  data: function () {
    return {
      field: {
        value: '',
        placeholder: '',
      },
      rows: 10,
      cols: 100,
      enable_editor: false,
      placeholder: '',
    };
  },
  created() {
    this.$set(this, 'field', this.props.field);
    if (this.field.rows) {
      this.rows = this.field.rows;
    }
    if (this.field.cols) {
      this.cols = this.field.cols;
    }
    if (typeof this.field.enable_editor !== 'undefined') {
      this.enable_editor = this.field.enable_editor;
    }
    if (typeof this.field.placeholder !== 'undefined') {
      this.placeholder = this.field.placeholder;
    }

    console.dir(this.placeholder)
  }
}
</script>
