import axios from 'axios'
import localforage from 'localforage'
import useTokenStore from "@/_core/store/useTokenStore";
import useApiErrorStore from "@/_core/store/useApiErrorStore";

function useApi() {
  const {accessToken} = useTokenStore();
  const {setApiError} = useApiErrorStore();


  axios.defaults.withCredentials = true;
  const store = localforage.createInstance({
    name: 'm2msim-cache'
  })

  const Api = axios.create({
    baseURL: BACKEND_DOMAIN,

    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })

  Api.interceptors.request.use(
    config => {
      if (accessToken) {
        config.headers.common["Authorization"] = "Bearer " + accessToken;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  Api.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 500) {
      setApiError(error.response.data.systemErrors[0] || {message: 'Internal server error'});
    }

    throw error;
  })

  return Api;
}

const Api = null;

export {Api};

export default useApi;
