function canvasService(canvas, touches, offsetX, offsetY = offsetX) {
  const ctx = canvas.getContext("2d");

  function initPositionCapture() {
    let prevClientX = null;
    let prevClientY = null;
    let boundary = 2;
    let scale = 4;

    function addPositionToArray(x, y) {
      const {left, bottom} = canvas.getBoundingClientRect();
      let passX;
      let passY;

      const _clientX = Math.floor((x - left) / scale);
      const _clientY = Math.abs(Math.floor((y - bottom) / scale));
      if (prevClientX !== null && prevClientY !== null) {
        passX = (_clientX <= (prevClientX - boundary) || _clientX >= (prevClientX + boundary))
        passY = (_clientY <= (prevClientY - boundary) || _clientY >= (prevClientY + boundary))
      } else {
        //ensure we record the first point.
        passX = true;
        passY = true;
      }

      //only log a point every 5mm for smoothness
      if (passX || passY) {
        prevClientX = _clientX;
        prevClientY = _clientY;
        touches.value.push(`${prevClientX},${prevClientY}`);
      }

    }

    function addMousePositionToArray({clientX, clientY}) {
      addPositionToArray(clientX, clientY);
    }

    function addTouchPositionToArray({pageX, pageY}) {
      addPositionToArray(pageX, pageY);
    }

    ['touchstart', 'touchmove'].forEach((event) => {
      canvas.addEventListener(event, (e) => {
        console.log('event', e)
        e.preventDefault();
        addTouchPositionToArray(e.touches[0]);
      }, false);
    });

    ['mousedown', 'mousemove'].forEach((event) => {
      canvas.addEventListener(event, (e) => {
        if (e.type === 'mousemove' && e.buttons !== 1) {
          return;
        }

        e.preventDefault();
        addMousePositionToArray(e);
      }, false);
    });
  }

  initPositionCapture();


  let flag = false;
  let prevX = 0;
  let currX = 0;
  let prevY = 0;
  let currY = 0;
  let dot_flag = false;

  let strokeStyle = "black";
  let lineWidth = 2;

  function initDrawing() {
    function findCoords(res, x, y) {
      function draw() {
        ctx.beginPath();
        ctx.moveTo(prevX, prevY);
        ctx.lineTo(currX, currY);
        ctx.strokeStyle = strokeStyle;
        ctx.lineWidth = lineWidth;
        ctx.stroke();
        ctx.closePath();
      }

      if (res === 'down') {
        prevX = currX;
        prevY = currY;
        currX = x - canvas.offsetLeft - offsetX;
        currY = y - canvas.offsetTop - offsetY;

        flag = true;
        dot_flag = true;
        if (dot_flag) {
          ctx.beginPath();
          ctx.fillStyle = strokeStyle;
          ctx.fillRect(currX, currY, 2, 2);
          ctx.closePath();
          dot_flag = false;
        }
      }
      if (res === 'up' || res === "out") {
        flag = false;
      }
      if (res === 'move') {
        if (flag) {
          prevX = currX;
          prevY = currY;
          currX = x - canvas.offsetLeft - offsetX;
          currY = y - canvas.offsetTop - offsetY;
          draw();
        }
      }
    }

    canvas.addEventListener("mousemove", function ({clientX, clientY}) {
      findCoords('move', clientX, clientY);
    }, false);
    canvas.addEventListener("mousedown", function ({clientX, clientY}) {
      findCoords('down', clientX, clientY);
    }, false);
    canvas.addEventListener("mouseup", function ({clientX, clientY}) {
      findCoords('up', clientX, clientY);
    }, false);
    canvas.addEventListener("mouseout", function ({clientX, clientY}) {
      findCoords('out', clientX, clientY);
    }, false);
    canvas.addEventListener("touchmove", function ({touches: [{pageX, pageY}]}) {
      findCoords('move', pageX, pageY);
    }, false);
    canvas.addEventListener("touchstart", function ({touches: [{pageX, pageY}]}) {
      findCoords('down', pageX, pageY);
    }, false);
    canvas.addEventListener("touchend", function () {
      findCoords('up', 0, 0);
    }, false);
    canvas.addEventListener("touchcancel", function () {
      findCoords('out', 0, 0);
    }, false);
  }


  initDrawing();
}

export default canvasService;
