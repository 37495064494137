<template>
  <div class="form-group row" :class="[{'form-input--error': $v.field.value.$error}, field.classes]">
    <label v-if="field.label" class="form__label" :for="field.name" :class="field.inputType !== 'date' ? 'bmd-label-floating' : ''">{{field.label}}</label>
    <div :class="{'form-group--error': $v.field.value.$error}" class="radio-field" v-for="(option, index) in options">
      <input
          @input="$v.field.value.$touch()"
          v-on:change="notify" type="radio"
          :id="randomId+'_radio_'+option.key"
          :name="field.name"
          :value="option.key"
          :checked="option.key === field.value"
          v-model="field.value"
      >
      <label :for="randomId+'_radio_'+option.key">{{option.value}}</label>
    </div>
    <div v-if="field.help" class="form-text">{{ field.help }}</div>
  </div>
</template>

<script>
import {fieldFunctions} from "../../mixins/fieldFunctions";
import { commonProperties } from '../../mixins/commonProperties'

export default {
  name: 'RadioGroupField',
  mixins: [
    commonProperties, fieldFunctions
  ],
  data: function () {
    return {
      options: [],
      field : {
        value: null
      },
      randomId: null,
      fieldValidations: {},
      enabledValidations: {}
    };
  },
  computed: {
    triggerCallback () {
      return this.$store.getters.triggerCallback
    },
    skyFormState(){
      return this.$store.getters.skyFormStateChange
    }
  },
  watch:{
    triggerCallbackWatch(to, from) {
      if(this.field.callback) {
        if (to.watch === this.field.callback.watch) {
          var values = this.$store.getters.callbackData(this.field.name);
          this.field.value = values[to.value];
        }
      }
    },
    skyFormState(to, from){
      if(to >= 1){
        this.fieldValidations = {0: {}}
        this.fieldValidations[0].method = "required"
      } else {
        this.fieldValidations = {}
      }
    }
  },
  methods: {
    nameWithId ({ key, value }) {
      return `${value}`
    },
    handleInput (e) {
      this.$emit('input', e);
      this.$store.commit('setCallbackTrigger', {watch: this.field.name, value: e.key});
    },
    //this is not ideal but for now as we can't easily pass an object as value, it will do.
    getObjectFromOptionsByKey(options, value){
      for(var option in options){
        if(options.hasOwnProperty(option)){
          if(options[option].key == value){
            return options[option];
          }
        }
      }
    },
    notify(e){
      //radio updated.
      let questionId = e.target.name.replace("question_radios_", '');
      console.log(e)
      console.log(e.target.name)
      console.log(e.target.defaultValue)
      console.log(questionId);
    }
  },
  created() {
    this.options = this.field.options;
    this.randomId = this.randomString(8);
  }
}
</script>
