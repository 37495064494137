// import Router from "../router";
import useApi from "@/_core/services/api";
import useTokenStore from "@/_core/store/useTokenStore";
import {useRouter} from "vue-router";
import useAttemptRefreshStore from "@/_core/store/useAttemptRefreshStore";

export default {
  async getGlobalData() {
    const api = useApi();
    const response = await api.get('/system/globalData');

    return response.data;
  },

  async getPage(path) {
    const api = useApi();
    try {
      return await api.get(path);
    } catch (error) {
      if (error.response) {
        //process a 403. As this is a page request, assume the user has logged out for now. Redirect to the login page
        if (error.response.status === 403) {
          throw error;
        }
        //process a 500. Something went very wrong, display a popup with the error (if there is one) -- Add logging?
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
    }
    return null;
  },
  processResponse(response, callback) {
    if (response.data) {
      if (response.data.hasOwnProperty('messages')) {
        response.data.messages.forEach((item) => {
          this.$store.commit('addMessage', item);
        })
      }
      if (response.data.hasOwnProperty('redirectToExternal')) {
        window.location = response.data.redirectToExternal;
      } else if (response.data.hasOwnProperty('redirect')) {
        if (response.data.redirect !== '' && response.data.redirect !== false) {
          this.$router.push(response.data.redirect);
        }
        return null;
      } else if (response.data.hasOwnProperty('refresh')) {
        this.$forceUpdate();
      }
    }

    if (typeof callback == "function") {
      callback();
    }
    ;
  },
  submitFile(uri, formData) {
    const Api = useApi();
    var fid;
    fid = Api.post(uri, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      return response.data.fid;
    }).catch((error) => {
      console.log(error);
      return null;
    });
    return fid;
  },
  async tryTokenRefresh() {
    const {refreshToken} = useTokenStore();
    if (refreshToken) {
      const api = useApi();
      return api.post('/api/v1/user/action/refresh', {
        refresh_token: refreshToken,
      });
    }
  },
  checkAuthenticatedResponse(response, path) {
    //if we're logged in then disallow access to the login page.
    if (response.data.authenticated && path === '/login') {
      response.data.authenticated = false;
    }

    const {setAttemptRefresh} = useAttemptRefreshStore();
    //if we're trying to logout, then do that.
    if (path.indexOf('logout') !== -1) {
      //Page.logout()
      //    .then(loggedOut => {
      //        response.data.redirect = "/";
      //    });
      // Remove the JWT:
      const {clearAllTokens} = useTokenStore();
      clearAllTokens();

      response.data.authenticated = false;
      setAttemptRefresh(false);
    }

    if (response.data.authenticated && path === '/') {
      setAttemptRefresh(true);
    }

    return response;
  },
  async checkAuthenticated(path) {
    //check if we're authenticated. If not, redirect to login
    const {attemptRefresh} = useAttemptRefreshStore();
    const api = useApi();
    try {
      let response = await api.get('/authenticated?' + Date.now()) // Don't cache authenticated request
      if (
        attemptRefresh === true &&
        !response.data.authenticated &&
        !path.match(/\blog(in|out)|forgot-password\b/)
      ) {
        try {
          const result = await this.tryTokenRefresh();
          if (result.data) {
            if (result.data.hasOwnProperty('access_token')) {
              const {setAccessToken} = useTokenStore();
              setAccessToken(result.data.access_token);
              response.data.authenticated = true;
            }
          }
          response = this.checkAuthenticatedResponse(response, path);

          return response.data
        } catch (error) {
          console.error(error)
          return response.data
        }
      } else {
        response = this.checkAuthenticatedResponse(response, path);

        return response.data;
      }

      return response.data
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status === 404) {
          this.throw404();
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(error);
      }

      return {};
    }

  },
  throw404() {
    const router = useRouter();
    router.push('/404.html');
  }
}
