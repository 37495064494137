<template>
  <div class="canvas">
    <div class="canvas__title intuit-subtitle">WHEN YOU DRAW, THE ARM DRAWS.</div>
    <p class="canvas__copy intuit-copy">Watch as the robotic arm mimics your movements while it creates the light drawing.</p>

    <canvas class="canvas__canvas" :width="width * scale" :height="height * scale" ref="canvasElement"></canvas>

    <div class="canvas__controls">
      <button type="button" class="intuit-button intuit-button--primary" @click="send">Submit</button>
      <button type="button" class="intuit-button" @click="clear">Reset</button>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import canvasService from "@/services/canvasService";
import useApi from "@/_core/services/api";
import useApiResponseProcessor from "@/_core/services/ApiResponseProcessor";
const {processResponse} = useApiResponseProcessor()

const api = useApi();

const props = defineProps({
  sendUrl: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    required: true,
  },
  height: {
    type: Number,
    required: true,
  },
  scale: {
    type: Number,
    default: 1,
  },
})

const canvasElement = ref(null);
const touches = ref([]);
const emit = defineEmits(['complete']);

onMounted(() => {
  const canvas = canvasElement.value;

  if (!canvas) {
    return;
  }

  canvasService(canvas, touches, 150 * props.scale);
});

async function send() {
  const response = await api.post(props.sendUrl,
      {
        touches: touches.value
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      });

  const {session} = await processResponse(response);

  emit('complete', session);
}

function clear() {
  const canvas = canvasElement.value;

  if (!canvas instanceof HTMLCanvasElement) {
    return;
  }

  touches.value = [];

  canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
}
</script>

<style lang="scss">
  .canvas {
    text-align: center;

    &__canvas {
      border: none;
      background-color: white;
      margin-bottom: 120px * $scale;
    }

    &__controls {
      display: flex;
      justify-content: center;
      gap: 100px * $scale;
    }
  }
</style>
